<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>
	<div>

        <a-row :gutter="24" class="px-15" style="margin-top: 0px;">
            <a-col :span="24" :md="16" :lg="16" :xl="16" class="mb-10">
                <h5>Admins</h5>
            </a-col>

			<a-col :span="24" :md="8" :lg="8" :xl="8" class="mt-10 text-right">
                <!-- <a-button
                    type="primary"
                    class="ml-15"
                    size="small"
                    @click="toCreateClassPage"
                    style="border-radius: 20px;">
                    Add User Identifications
                    <a-icon type="plus" theme="outlined" />
                </a-button> -->
			</a-col>
		</a-row>

		<div v-if="!loadingData" class="mb-20 mt-20">

			<a-row :gutter="24" class="px-20">

				<a-col :span="24"  :md="24" :lg="24" :xl="24" class="mb-24 pb-10">
					<a-card>
                        
                        <a-row :gutter="24" class="mb-0 pb-0 mt-15">
                            <a-col :span="24" :md="24" :lg="24" :xl="24" class="mb-0 pb-0">
                                <a-table
                                    class="ant-table-striped"
                                    :columns="columns" 
									:data-source="admins"
									:loading="pagination.loading" 
									:pagination="false" 
									:rowKey="record => record.uuid"
									width="100%" 
									style="width: 100%;">


                                        <template slot="s_no" slot-scope="s_no, record, index">
                                            {{ (pagination.currentPage - 1) * pagination.perPage + index + 1 }}
                                        </template>

                                        <template slot="fullName" slot-scope="row"> 
                                            <a-avatar :src="row.profile" shape="square" class="mr-5"/>
                                           {{ row.firstName }}  {{ row.lastName }}
                                        </template>

                                        <template slot="contacts" slot-scope="row"> 
                                            <p class="m-0">
                                                <font-awesome-icon icon="fa-solid fa-phone" class="mr-5" style=""/> {{ row.phoneNumber != null ? `${row.phoneNumber }` : 'N/A' }}  <br/>
                                                <font-awesome-icon icon="fa-solid fa-envelope" class="mr-5" style=""/> {{ row.email != null && row.email != null ? `${row.email }` : 'N/A' }}
                                            </p>
                                        </template>

                                        <template slot="gender" slot-scope="row"> 
                                            {{ row.gender ? 'Female' : 'Male' }}
                                        </template>

                                        <template slot="country" slot-scope="row"> 
                                            {{ row.countryCode.toUpperCase() }}/=
                                        </template>

                                        <template slot="lastSeen" slot-scope="row"> 
                                            {{ $Moment(row.lastSeen).format('DD MMM YYYY') }}
                                        </template>

                                        <template slot="action" slot-scope="row"> 
                                            <a-button
                                                id="textBtn"
                                                type="text"
                                                class="ml-15"
                                                size="small"
                                                @click="viewClass(row)"
                                                style="border-radius: 20px;">
                                                
                                                <a-icon type="eye" theme="outlined" />
                                            </a-button>
                                        </template>
                                        
                                </a-table>
                            </a-col>
                        </a-row>
                        
                    </a-card>
				</a-col>

                <a-col :span="24"  :md="24" :lg="24" :xl="24" class="mb-24 pb-10 text-right">
                    <a-pagination type="success" class="btn btn-silabu" v-model="currentPage" :total="pagination.total" :pageSize="pagination.perPage" show-less-items />
                </a-col>

			</a-row>

		</div>

		<div v-if="loadingData">

			<a-row type="flex" justify="space-around" align="middle" class="px-20" style="min-height: 80vh">

				<a-col :span="24" :md="24" :lg="24" class="text-center  justify-items-center">

					<a-spin class="text-primary" size="large"/>

				</a-col>

			</a-row>
		</div>


	</div>
</template>

<script>
	import {DoubleBounce} from 'vue-loading-spinner'
	import WidgetCounter from '../../components/Widgets/WidgetCounter';


    const columns = [
        {
            title: 'S/N',
            scopedSlots: { customRender: 's_no' },
            width: 30
        },
        {
            title: 'Full Name',
            scopedSlots: { customRender: 'fullName' },
            width: 200
        },
        {
            title: 'Gender',
            scopedSlots: { customRender: 'gender' },
            width: 80
        },
        {
            title: 'Contacts',
            scopedSlots: { customRender: 'contacts' },
            width: 150
        },
        {
            title: 'Country',
            scopedSlots: { customRender: 'country' },
            width: 100
        },
        {
            title: 'Last Seen',
            scopedSlots: { customRender: 'lastSeen' },
            width: 100
        },
        {
            title: 'Action',
            scopedSlots: { customRender: 'action' },
            width: 100
        },
    ];

	export default ({
		
		components: {
			DoubleBounce,
			WidgetCounter,
		},

		data() {
			return {

				loadingData: false,

				admins: [],

                studentRole: null,
                tutorRole: null,
                instituteRole: null,
                institution: {},

                columns,
				currentPage: 1,
                pagination: {
					search: '',
                    filter: 'all',
                    perPage: 10,
                    currentPage: 1,
                    totalPages: 1,
                    perPageOptions: [10, 20, 30, 50],
                    total: 0
                },

				
      		}
		},
		watch: {
            // pagination: {
				
            //     handler() {
            //         // this.getAdmins();
			// 		console.log(this.pagination.currentPage)
            //     },
            // },
			currentPage: {
                handler() {
					console.log(this.currentPage)
					this.pagination.currentPage = this.currentPage;
					this.getAdmins(this.educationLevel);
                },
            },
            deep: true,
        },
		mounted () {
			let breadcrumbs = [
				{ title: 'Admins', url: '/institutions/admins', isActive: true }
			];
			
			this.$root.$refs.Breadcrumbs.initializeBreadCrumbs(breadcrumbs)
		},
		created() {
            this.$root.$refs.InstitutionAdmins = this;
            // this.getRoles();
		},
		methods: {

			formatName(name) {
				let formattedName = name != null ? `${name[0].toUpperCase()}${name.substring(1).toLowerCase()}` : name;
                return formattedName;
            },


            async getRoles() {

				this.pagination.loading = true;

                let institutionInfo = await localStorage.getItem("institution_details")

				this.institution = institutionInfo != null ? JSON.parse(institutionInfo) : {}

                let url = `${this.$BACKEND_URL}/roles`;

                this.$AXIOS.get(url).then(async(response) => {

					if(response.data.data != null && response.data.data.length > 0) {

						response.data.data.forEach(element => {
							if(element.code == 'student' && element.category == 'silabu') {
								this.studentRole = element.uuid;
							}

							if(element.code == 'teacher' && element.category == 'silabu') {
								this.tutorRole = element.uuid;
							}

                            if(element.code == 'admin' && element.category == 'institute') {
								this.instituteRole = element.uuid;
							}
                            
						});

                        this.getAdmins();
						
					}
					
                }).catch(async(error) => {
					this.pagination.loading = false;
                });
            },

			
			async getAdmins() {

                this.pagination.loading = true;
        
                const { currentPage, perPage, search } = this.pagination;

                let url = `${this.$BACKEND_URL}/users?institution=${this.institution.uuid}&role=${this.instituteRole}&page=${currentPage}&size=${perPage}`;

                this.$AXIOS.get(url).then((response) => {

                    this.admins = response.data.data;
                    this.pagination.totalPages = response.data.pagination != null ? response.data.pagination.totalPages : 0;
                    this.pagination.total = response.data.pagination != null ? response.data.pagination.totalItems : 0;
                    this.pagination.currentPage = response.data.pagination != null ? response.data.pagination.currentPage : 0;
                
                    this.pagination.loading = false;

                }).catch(error => {
                    this.pagination.loading = false;
        
                    this.notify(error.message ?? "Something went wrong, try again please!", 'error')
                
                })
            },


            notify(message, type) {
                notification[type]({
                    message: message,
                    description: '',
                    placement: 'topRight',
                    duration: 3,
                });
            },


		}
	})

</script>

<style>

.double-bounce1[data-v-33aee302], .double-bounce2[data-v-33aee302] {
	background-color: #734EBF !important;
	height: 70px;
	width: 70px;
}

.ant-pagination-item-active a {
    color: #FFFFFF !important;
	background-color: #734EBF;
}

#classImg {
	border-radius: 5px;
}

a:hover {
    color: inherit !important;
}
</style>